import { createRouter, createWebHistory } from 'vue-router'

const NotFound = { template: '<div>Page Not Found 404</div>' }

const routes = [
  {
    path: '/',
    redirect: { name: 'productCertify' }
  },
  {
    path: '/sn/:sn?',
    name: 'productCertify',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductCertify.vue'),
    props: (route) => {
      // console.log('route:', route)
      return {
        serialNumber: route.params.sn
      }
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
