export default {
  home: {
    Weinview_Product_Verification: 'Weinview Product Verification',
    Sticker_SN: 'Sticker SN',
    Please_enter_the_SN_from_the_sticker: 'Please enter the SN from the sticker.',
    Verification_Code: 'Verification Code',
    Please_scratch_off_msg: 'Please scratch to obtain code.',
    Verify: 'Verify',
    Language: 'Language',
    Please_wait_msg: 'Please wait {qty} second',
    You_are_not_human: 'You are not human',
    Serial_number_length_left: '{qty} character left',
    Serial_number_length_error: 'SN is 9 characters in length'
  },
  first: {
    Weinview_Product_Verification: 'Weinview Product Verification',
    Result_Verified: 'Result: Verified',
    This_is_a_first_time_verification_msg: 'This is a first-time verification. Thank you for purchasing Weinview HMI product.。'
  },
  notFirst: {
    Weinview_Product_Verification: 'Weinview Product Verification',
    Result_Verified: 'Result: Verified',
    This_is_not_a_first_time_verification_msg: 'This is not a first-time verification. Thank you for purchasing Weinview HMI product.',
    Date_of_first_verification: 'Date of first verification',
    Our_record_indicates_this_verification_msg: 'Our record indicates this verification code has been used in the past. If the query did not originate from you, it is possible that the code was leaked, or the device could be a counterfeit product. Please contact us for further information.'
  },
  fail: {
    Weinview_Product_Verification: 'Weinview Product Verification',
    Result_Failed: ' Result: Failed',
    The_verification_code_you_entered_is_invalid_msg: 'The verification code you entered is invalid, so the device could be a counterfeit product. Please contact us for further information.'
  }
}
